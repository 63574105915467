import React, { Fragment, useContext, useEffect, useState } from 'react'
import './index.less'
import { v4 as uuid } from 'uuid';
import Mdast from '@ylzcc/mdast'
import SvgIcon from '../../../components/SvgIcon'
import QuesAns from '../../../components/QuesAns'
import { CSSTransition } from "react-transition-group"
import SharedDataContext from '../../../utils/share'
import { Modal, message } from 'antd'
import { Type, courseTypes, projectType } from '../../../utils/commonTypes'
import ImportModal from './components/ImportModal'
import BlogEnvModal from './components/BlogEnvModal'
import { read, rm, write } from '../../../api/modules/fs'
import { base64ToText, getQueryStringValue, isJsonString, textToBase64 } from '../../../utils'
import { ExclamationCircleFilled } from '@ant-design/icons';
import ActivityForm from '../../../components/MdEditor/ActivityForm'
import Activities from '../../../components/MdEditor/Activities';
import EnvModal from './components/EnvModal';
import RunModal from './components/runModal';
import ScoreDrawerMark from './components/ScoreDrawerMark';
import Invite from './components/Invite' //邀请协作
import Collaboration from './components/Collaboration' //同时在线
import ActivitiesManage from '@/library/ActivitiesManage';
import { queryActivityList, removeActivity, saveActivity } from '@/api/modules/activity';
import Demo from '@/library/Demo';
import CodeTest from '@/library/CodeTest';
import ChoiceQuestion from '@/library/ChoiceQuestion';

type RightSiderProps = {
    modal: string | null            // 当前模式  '1' 创作   '0'  学习
    examType: string | null         // 考试类型  '0' 表示考试类型
    isPreview: any                  // 是否是预览模式    '1' 表示预览
    courseType: string | null       // 课程类型 COURSE：课程 BLOG：博客
    courseCode: string | null       // 课程Code
    params?: any                     //工作台参数
    category?: any                  //课程类型
    editMode?: any                   //新版编辑器：显示活动管理
    ref?: any
    examDetai?: any //考试详情
    changePath?: () => void   // 改变小节
}

// 菜单类型
type menusType = {
    id: string             // 唯一标识
    icon: React.ReactNode  // 图标
    title: React.ReactNode // 标题
    open: boolean          // 是否开放
    show: boolean          // 是否显示
    light: boolean         // 是否高亮
    clickLight?: boolean   // 是否需要点击高亮 
    className?: string     // 类名
    onClick?: () => void   // 点击函数
}


const { confirm } = Modal;

const RightSider: React.FC<RightSiderProps> = (props) => {

    const { params, modal, examDetai, examType, category = 'text', isPreview, courseType, courseCode, editMode } = props



    // 获取共享数据
    const { section, globalEnvStatus, editorRef, type: examOtherType, _projectType, chapterId } = useContext(SharedDataContext)

    //运行部署弹窗
    const [runModal, setRunModal] = useState(false)

    //课程导入弹窗
    const [importModal, setImportModal] = useState(false)

    // 活动类型
    const [type, setType] = useState<any>('')

    // 设置当前编辑数据
    const [current, setCurrent] = useState<any>({})

    // 小节的活动配置信息
    const [activitiesConfig, setActivitiesConfig] = useState<any>({ mark: false })

    // 显示活动管理
    const [showActivities, setShowActivities] = useState(false)

    // 显示工程实操题判分
    const [showMark, setShowMark] = useState(false)

    //存储当前添加位置
    const [editorPos, setEditorPos] = useState<any>()

    //md文本
    const [mdText, setMdText] = useState<any>()


    // 默认初始菜单
    const defaultMenus: menusType[] = [
        {
            id: '11',
            icon: <SvgIcon iconClass={'project-run'} style={{ width: '20px', height: '20px' }} />,
            // icon: <SvgIcon iconClass={'project-run'} style={{ width: '20px', height: '20px', fill: section?.template?.experEnv ? '#9d9d9d' : "#666666" }} />,
            title: <span>运行<br></br>部署</span>,
            // title: <span style={{ color: section?.template?.experEnv ? '#9d9d9d' : '#666666' }}>运行<br></br>部署</span>,
            open: true,
            show: examType === '0' && examOtherType === Type.PROJECT,
            light: false,
            onClick: () => {
                // console.log(section);
                if (section?.template?.experEnv) {
                    setRunModal(true)
                } else {
                    runnWarning()
                }

            }
        },
        {
            id: '12',
            icon: <SvgIcon iconClass={'project-mark'} style={{ width: '20px', height: '20px' }} />,
            title: '判分',
            open: true,
            show: examType === '0' && examOtherType === Type.PROJECT && _projectType === projectType.MARK,
            light: false,
            onClick: () => {
                setShowMark(true)
            }
        },
        {
            id: '8',
            icon: <SvgIcon iconClass={'right-env'} style={{ width: '20px', height: '20px' }} />,
            title: '环境',
            open: true,
            show: modal === '1' && (courseType === courseTypes.BLOG || courseType === courseTypes.EXPERIENCE) && examType !== '0',
            light: false
        },
        {
            id: '5',
            icon: <SvgIcon iconClass={'right-quesandans'} style={{ width: '20px', height: '20px' }} />,
            title: <span>问答<br></br>讨论</span>,
            open: true,
            show: modal === '0' && examType !== '0' && isPreview !== '1' && !!getQueryStringValue('token'),
            light: false
        },
        // {
        //     id: '6',
        //     icon: <SvgIcon iconClass={'right-note'} style={{ width: '20px', height: '20px' }} />,
        //     title: '笔记',
        //     open: false,
        //     show: modal === '0' && examType !== '0' && isPreview !== '1',
        //     light: false
        // },
        {
            id: '7',
            icon: <SvgIcon iconClass={'daoru'} style={{ width: '20px', height: '20px' }} />,
            title: <span>导入</span>,
            open: true,
            show: modal === '1' && examType !== '0',
            light: false,
            onClick: () => {
                setImportModal(true)
            }
        },
        {
            id: '10',
            icon: <SvgIcon iconClass={'activity'} style={{ width: '20px', height: '20px' }} />,
            title: <span>活动<br></br>管理</span>,
            open: true,
            show: params?.path && modal === '1' && editMode === 'new' && examType !== '0' && section.type !== 'video',
            light: false,
            onClick: async () => {
                // let type = 'demo'
                // if (type === 'demo' || type === 'codingTest' || type === 'choiceQuestion') {
                //     switch (type) {
                //         case 'demo':
                //             setType('experiment')
                //             break;
                //         case 'codingTest':
                //             setType('validate')
                //             break;
                //         case 'choiceQuestion':
                //             setType('question')
                //             break;
                //         default:
                //             break;
                //     }
                //     setCurrent(undefined)
                //     return
                // }
                const res = await getAllActivies()
                // console.log(res);

                if (res?.length) {
                    setActivities([...res])
                    setShowActivities(true)
                } else {
                    showWarning()
                }
            }
        },
        {
            id: '13',
            icon: <></>,
            title: <Collaboration />,
            open: true,
            show: true,
            light: false,
            clickLight: false,
            className: 'collaboration'
        },
        {
            id: '14',
            icon: <></>,
            title: <Invite onCanInvite={(value) => {
                if (!value) {
                    menus.forEach((v: any) => {
                        if (v?.id === '14') {
                            v.show = false
                        }
                    })
                    setMenus([...menus])
                }
            }} />,
            open: true,
            show: examType !== '0' && isPreview !== '1',
            // show: modal === '0' && examType !== '0' && isPreview !== '1',
            light: false,
            clickLight: false
        },
        {
            id: '15',
            icon: <SvgIcon iconClass={'resetResource'} style={{ width: '20px', height: '20px' }} />,
            title: <span>重置<br></br>课程</span>,
            open: true,
            show: modal === '0' && courseType === courseTypes.COURSE,
            light: false,
            clickLight: false,
            onClick: () => {
                confirm({
                    title: '确认恢复默认课程资源？',
                    content: '恢复默认课程资源，将会更新课程内容+课程资源,自定义内容不会被覆盖。',
                    okText: '确定',
                    centered: true,
                    className: 'warn',
                    width: 400,
                    cancelText: '取消',
                    onOk: () => {
                        window.postMessage({
                            type: 'reGitPull',
                        })
                    },
                    onCancel: () => { }
                })
            }
        },
        {
            id: '9',
            icon: <SvgIcon iconClass={'right-create'} style={{ width: '20px', height: '20px' }} />,
            title: <span>创作<br></br>手册</span>,
            open: true,
            show: true,
            light: false,
            clickLight: false,
            onClick: () => {
                window.postMessage({
                    type: 'pushContainer',
                    component: 'Webview',
                    value: {
                        path: 'https://oscollegedoc.helplook.com/',
                        name: '创作手册',
                    }
                })
            }
        },
        // {
        //     id: '1',
        //     icon: <SvgIcon iconClass={'right-ai'} style={{ width: '20px', height: '20px' }} />,
        //     title: 'AI',
        //     open: false,
        //     show: true,
        //     light: false
        // },
        // {
        //     id: '2',
        //     icon: <SvgIcon iconClass={'right-bushu'} style={{ width: '20px', height: '20px' }} />,
        //     title: '部署',
        //     open: false,
        //     show: true,
        //     light: false
        // },
        // {
        //     id: '3',
        //     icon: <SvgIcon iconClass={'right-file'} style={{ width: '20px', height: '20px' }} />,
        //     title: '文档',
        //     open: false,
        //     show: true,
        //     light: false
        // },
        // {
        //     id: '4',
        //     icon: <SvgIcon iconClass={'right-setting'} style={{ width: '20px', height: '20px' }} />,
        //     title: '设置',
        //     open: false,
        //     show: true,
        //     light: false
        // }
    ]

    // 显示提示
    const showWarning = () => {
        Modal.warning({
            title: '温馨提示',
            content: '您还没有添加任何活动，快去添加活动试试吧~',
            centered: true,
            okText: '我知道了'
        })
    }

    // 运行部署的弹窗
    const runnWarning = () => {
        Modal.warning({
            title: '温馨提示',
            content: '无法进行运行部署，请先设置实验环境。',
            centered: true,
            okText: '我知道了'
        })
    }

    // 菜单
    const [menus, setMenus] = useState(defaultMenus)

    // // 获取活动配置信息
    // const fetchRead = () => {
    //     read({
    //         ...params,
    //         file: 'activities.json'
    //     }).then(res => {
    //         const data = res.data
    //         const _data = base64ToText(data)
    //         const _config = isJsonString(_data) ? JSON.parse(_data) : {}
    //         setActivitiesConfig(_config)
    //     }).catch(() => {
    //         setActivitiesConfig({
    //             mark: false
    //         })
    //     })
    // }

    // 设置某个菜单为高亮的状态   表示选中状态
    const setMenusLight = (id: string, light: boolean = true) => {
        const res = menus?.map((item: menusType) => {
            // 针对问答做处理   当前没有选中小节的时候无法打开
            if (id === '5') {
                if (light && !section.path) {
                    light = false
                    message.destroy()
                    message.warning('请先打开一个小节!')
                }
                setShowQues(light)
            }
            // 针对博客环境进行处理
            if (id === '8') {
                if (light && globalEnvStatus) {
                    light = false
                    Modal.confirm({
                        title: "温馨提示",
                        content: '您正在使用环境，无法更改，请先关闭环境。',
                        cancelText: '我知道了',
                        okText: '关闭环境',
                        centered: true,
                        width: 400,
                        onOk() {
                            window.postMessage({
                                type: 'closeEnv'
                            })
                        },
                        onCancel() { }
                    })
                }
                setShowBlogEnv(light)
            }
            // 创作手册无法做到高亮
            if (id === '7' || id === '11') {
                return { ...item }
            }
            if (id === item?.id) {
                return { ...item, light }
            } else {
                return { ...item }
            }
        })
        setMenus(res)
    }

    const [showQues, setShowQues] = useState(false)          // 显示问答菜单栏
    const [showBlogEnv, setShowBlogEnv] = useState(false)    // 显示博客环境弹框
    const [showEnv, setShowEnv] = useState(false)            // 显示直接设置小节的环境弹框

    // 所有活动
    const [activities, setActivities] = useState<any>([])

    // 接口保存活动
    const fetchSaveActivity = async (data: any) => {
        // 是新增还是编辑，新增加需要设置id
        let edit = true
        if (!data?.id) {
            edit = false
            data.id = `activity-${uuid()}`
        }
        data.file = 'activity.json'
        const res = await saveActivity(
            {
                path: params?.path,
                repo: params?.repo
            },
            data
        ).catch(() => { })
        if (!res) return
        setActivities(res)
        // 编辑状态需通知
        if (edit) {
            // 供文本阅读器使用
            window.postMessage({
                type: 'updateActivity',
                id: data.id
            })
            return
        }
        // console.log('🚀', editorPos, data)
        editorRef?.current?.addActive({
            pos: editorPos,
            id: data.id,
            language: data.type
        })
    }

    // // 写入活动 refresh: 需要重新获取最新活动
    // const fetchWriteActivity = (data: any, refresh?: boolean) => {
    //     const edit = data?.id
    //     data.id = data?.id || `activity-${uuid()}`
    //     delete data?.edit
    //     const file = `${data?.id}.json`
    //     write({
    //         ...params,
    //         file,
    //         data: textToBase64(JSON.stringify(data))
    //     }).then(() => {
    //         // 编辑状态需重新获取数据
    //         if (edit) {
    //             refresh !== false && getLast()
    //             // 修改时间，对应语法时间修改
    //             editorRef?.current?.update()
    //             window.postMessage({
    //                 type: 'updateActivity',
    //                 id: data.id
    //             })
    //             return
    //         }
    //         // data作为回传到编辑器的数据
    //         // console.log(data);
    //         editorRef?.current?.addActive({
    //             pos: editorPos,
    //             id: data.id,
    //             language: data.type
    //         })
    //     })
    // }

    // 删除活动
    const fetchDeleteActivity = async (data: any) => {
        const res = await removeActivity(
            {
                path: params?.path,
                repo: params?.repo
            },
            {
                file: 'activity.json',
                id: data.id
            }
        ).catch(() => { })
        if (!res) return
        setActivities(res)
        // 同步删除编辑器里面的代码
        window.postMessage({
            type: 'deleteSelf',
            id: data.id
        })
        if (data?.type === 'experiment') {
            // 示例演示：同步删除（资源管理=>演示脚本）
            let paths = data?.codePath?.split('/')
            if (!paths?.length) return
            paths?.pop()
            await rm({
                ...params,
                key: params?.user,
                workdir: true,
                data: {
                    path: paths?.join('/')
                }
            }).catch(() => { })
        }

        // rm({
        //     ...params,
        //     key: params?.user,
        //     data: {
        //         path: `${data?.id}.json`
        //     }
        // }).then(() => {
        //     // window.postMessage({
        //     //     type: 'deleteSelf',
        //     //     id: data.id
        //     // })
        //     if (data?.type === 'experiment') {
        //         // 示例演示：同步删除（资源管理=>演示脚本）
        //         let paths = data?.codePath?.split('/')
        //         if (!paths?.length) return
        //         paths?.pop()
        //         rm({
        //             ...params,
        //             key: params?.user,
        //             workdir: true,
        //             data: {
        //                 path: paths?.join('/')
        //             }
        //         }).catch(() => { })
        //     }
        //     const ast = Mdast.parse(mdText)
        //     const _actives: any = ast?.children?.filter((v: any) => v?.type === 'code' && (v?.properties?.ext === "exec" || v?.properties?.ext === "copy" || v?.properties?.ext === "validate" || v?.properties?.ext?.startsWith('active')))
        //     let index = 0
        //     const activity = _actives?.filter((e: any, i: number) => {
        //         const value = e?.value
        //         if (isJsonString(value)) {
        //             const _value = JSON.parse(value)
        //             if (_value?.id === data?.id) {
        //                 index = i
        //                 return true
        //             }
        //             return false
        //         }
        //     })
        //     console.log(JSON.parse(activity[0].value)?.id, activities);

        //     const newArray = activities.filter((item: any) =>
        //         item?.id !== JSON.parse(activity[0].value)?.id);
        //     console.log(newArray);

        //     setActivities(newArray)
        //     editorRef?.current?.changeMdTest(activity)

        // }).catch(() => {
        //     message.error('删除失败')
        // })
    }


    // 获取最新的活动数据
    // const getLast = async () => {
    //     const res = await getAllActivies()
    //     setActivities(res)
    // }

    // const getAllActivies: any = async () => {
    //     let text = editorRef?.current?.getMdText()
    //     // console.log(Text);

    //     setMdText(text)
    //     if (text) {
    //         const ast = Mdast.parse(text)
    //         const _actives = Mdast.actives(ast)


    //         if (_actives.length) {
    //             let _res: any = []
    //             for await (const iterator of _actives) {
    //                 const value = iterator?.value
    //                 if (isJsonString(value)) {
    //                     const _value = JSON.parse(value)
    //                     const id = _value?.id

    //                     if (id) {
    //                         const data = await fetchReadActivity(id)

    //                         if (data) {
    //                             _res.push(data)
    //                         }
    //                     }
    //                 }
    //             }
    //             return _res

    //         }
    //     }
    //     return []
    // }
    const getAllActivies: any = async () => {
        return await queryActivityList({
            file: 'activity.json',
            path: params?.path,
            repo: params?.repo
        }).catch(() => { })
    }

    // // 读取活动内容
    // const fetchReadActivity = async (file: string) => {

    //     try {
    //         const res = await read({
    //             ...params,
    //             file: `${file}.json`
    //         })
    //         const data = res.data
    //         const _data = base64ToText(data)
    //         return isJsonString(_data) ? JSON.parse(_data) : ''
    //     } catch (error) {
    //         return ''
    //     }
    // }

    // 设置活动配置信息
    // const fetchWrite = (data: any) => {
    //     write({
    //         ...params,
    //         file: 'activities.json',
    //         data: textToBase64(JSON.stringify(data))
    //     })
    // }

    // const activeModal = () => {

    //     message.destroy()
    //     // message.warning('请先设置小节的环境镜像~')
    //     confirm({
    //         title: '温馨提示',
    //         icon: <ExclamationCircleFilled rev={undefined} />,
    //         content: '您还未配置实验环境，请先配置。',
    //         okText: '去配置',
    //         centered: true,
    //         className: 'warn',
    //         width: 400,
    //         cancelText: '我知道了',
    //         onOk() {
    //             setShowEnv(true)
    //             // changePath?.()
    //         }
    //     });

    // }

    // 当当前没有小节时关闭问答
    useEffect(() => {
        if (!section?.path) {
            setMenusLight('5', false)
        }
    }, [section?.path])

    // 博客显示"环境"
    useEffect(() => {
        if (courseType === courseTypes.BLOG || courseType === courseTypes.EXPERIENCE) {
            setMenus(defaultMenus)
        }
    }, [courseType])

    useEffect(() => {
        // if (params?.path && modal === '1') {
        //     if (editMode === 'new') {
        //         fetchRead()
        //     }
        // }
        setMenus(defaultMenus)
    }, [params?.path, modal, editMode, section])



    const handleAddActive = (e: any) => {
        const data = e.data
        // console.log(data);
        let type = data.type
        const value = data.value
        setEditorPos(data?.value?.pos ?? 0)
        if (type === 'demo' || type === 'codingTest' || type === 'choiceQuestion') {
            switch (type) {
                case 'demo':
                    setType('experiment')
                    break;
                case 'codingTest':
                    setType('validate')
                    break;
                case 'choiceQuestion':
                    setType('question')
                    break;
                default:
                    break;
            }
            setCurrent(undefined)
        } else if (type === 'editActive') {
            let data
            try {
                data = JSON.parse(value.data)
            } catch (error) { }
            setType(data?.type)
            setCurrent(data)
            // window.postMessage({
            //     type: 'updateActivity',
            //     id: data.id
            //   })
        }

    }

    useEffect(() => {
        window.addEventListener('message', handleAddActive)
        return () => {
            window.removeEventListener('message', handleAddActive)
        }
    }, [type, section])

    return (
        <div className='rightsider'>
            {
                menus?.map((item: menusType) => {
                    return <Fragment key={item?.id}>
                        {
                            !item.show ? <></> : (
                                <div className={`${item?.open ? (item?.light ? 'rightsider-item rightsider-item-light' : 'rightsider-item rightsider-item-open') : 'rightsider-item rightsider-item-openno'}${item?.className ? ` ${item?.className}` : ''}`} onClick={() => {
                                    if (item.clickLight !== false) {
                                        // 邀请协作：点击无需高亮
                                        setMenusLight(item.id, !item.light)
                                    }
                                    item?.open && item?.onClick?.()
                                }}>
                                    {item?.icon}
                                    <span>{item?.title}</span>
                                </div>
                            )
                        }
                    </Fragment>
                })
            }

            <CSSTransition
                in={showQues}
                timeout={500}
                classNames={{
                    enter: 'animation-enter',
                    enterActive: 'animation-enter-active',
                    exit: 'animation-exit',
                    exitActive: 'animation-exit-active'
                }}
                mountOnEnter
                unmountOnExit
            >
                <QuesAns onClose={() => { setMenusLight('5', false) }} />
            </CSSTransition>

            <ImportModal courseType={courseType || ''} ifTrue={importModal} courseCode={courseCode ?? ''} changeValue={() => {
                setImportModal(!importModal)
            }}></ImportModal>
            <RunModal ifTrue={runModal} examDetai={examDetai} section={section} params={params} changeValue={() => {
                setRunModal(!runModal)
            }}></RunModal>

            {/* <ActivityForm params={params} template={section?.template} activity={{
                type: type,
                category: category,
                mark: false,
                data: current
            }} show={type} close={() => {
                setType('')
            }} finish={(data) => {
                fetchWriteActivity({
                    ...data,
                    type
                })
            }} /> */}

            {/* 三种活动 现在只有选择题走这个*/}
            {/* <ActivityForm
                activity={{
                    type: 'question',
                    category: category,
                    mark: activitiesConfig?.mark,
                    data: current
                }}
                show={type === 'question' && type}
                close={() => {
                    setType('')
                }}
                finish={(data) => {
                    fetchSaveActivity({
                        ...data,
                        type
                    })
                }} /> */}

            {/* 选择题 */}
            <ChoiceQuestion
                open={type === 'question' && type}
                onClose={() => { setType('') }}
                value={current}
                time={false}
                finish={async (data) => {
                    fetchSaveActivity({
                        ...data,
                        type
                    })
                }}
                chapterId={chapterId}
                courseCode={courseCode!}
            />

            {/* 示例演示 */}
            <Demo
                open={type === 'experiment' && type}
                onClose={() => { setType('') }}
                value={current}
                time={false}
                finish={async (data: any) => {
                    if (!data?.codePath) {
                        data.codePath = `活动能力/${uuid()}/演示脚本.sh`
                    }
                    await write({
                        ...params,
                        workdir: true,
                        file: data.codePath,
                        data: textToBase64(data?.code)
                    }).catch(() => { })
                    await fetchSaveActivity({
                        ...data,
                        type
                    })
                }}
                chapterId={chapterId}
                courseCode={courseCode!}
                template={section?.template}
            />
            {/* 编码测验 */}
            <CodeTest
                open={type === 'validate' && type}
                onClose={() => { setType('') }}
                value={current}
                time={false}
                finish={async (data: any) => {
                    await fetchSaveActivity({
                        ...data,
                        type
                    })
                }}
                chapterId={chapterId}
                courseCode={courseCode!}
            />

            {/* 活动管理 */}
            <ActivitiesManage
                activitiesConfig={activitiesConfig}
                activities={activities}
                open={showActivities}
                onClose={() => {
                    setShowActivities(false)
                    setMenusLight('10', false)
                }}
                onEdit={(value: any, type: any) => {
                    setType(value?.type)
                    setCurrent(value)
                }}
                onBaseChange={(value: any, mark: any) => { }}
                onDelete={(value: any) => {
                    fetchDeleteActivity(value)
                }}
            />

            {/* <Activities activitiesConfig={activitiesConfig} activities={activities} open={showActivities} onClose={() => {
                setShowActivities(false)
                setMenusLight('10', false)
            }} onEdit={(value: any, type: any) => {
                if (type === 1) {
                    // 直接编辑分数
                    fetchWriteActivity(value)
                    return
                }
                setType(value?.type)
                setCurrent(value)
            }} onBaseChange={(value: any, mark: any) => {
                const _activitiesConfig = {
                    ...activitiesConfig,
                    ...value
                }
                setActivitiesConfig(_activitiesConfig)
                fetchWrite(_activitiesConfig)
            }} onDelete={(value: any) => {
                fetchDeleteActivity(value)
            }} /> */}

            <BlogEnvModal open={showBlogEnv} courseType={courseType} onClose={() => { setMenusLight('8', false) }} />
            <EnvModal open={showEnv} onClose={() => { setShowEnv(false) }} />
            <ScoreDrawerMark open={showMark} onClose={() => { setShowMark(false); setMenusLight('12', false) }} title='工程项目类实操题判分' />
        </div>
    )
}

export default RightSider